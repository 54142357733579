<template>
	<div class="constrained flex-center h-full">
		<div class="icon-loader">
			<svg
				class="icon-loader__spinner-icon"
				width="112px"
				height="112px"
				viewBox="0 0 112 112"
				xmlns="http://www.w3.org/2000/svg"
			>
				<circle
					class="path"
					fill="none"
					stroke-width="8"
					stroke-linecap="round"
					cx="56"
					cy="56"
					r="48"
				></circle>
			</svg>
			<i v-if="icon" class="icon-loader__lock-icon icon">
				<svg-icon width="40"
					height="40"
					class="fill-current"
					:class="`text-${color}`" :name="`${icon}`" />
			</i>
			<div v-if="text" class="icon-loader__text">
				{{ text }}
			</div>
			<div v-if="$slots.default" class="icon-loader__text">
				<slot></slot>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: 'loader',
	props: {
		color: {
			type: String,
			default: 'green-darker',
		},
		icon: {
			type: String,
			required: false,
		},
		text: {
			type: String,
			required: false,
		},
	},
}
</script>

<style lang="scss">
$offset: 302;
$duration: 2.5s;
$spinner-width: 112;

.icon-loader {
	position: relative;
}

.icon-loader__spinner-icon {
	position: relative;
	left: 50%;
	margin-left: #{calc($spinner-width/-2)}px;
	animation: rotator $duration linear infinite;
}

.icon-loader__lock-icon {
	position: absolute;
	margin-left: -20px;
	top: 36px;
	left: 50%;
}

.icon-loader__text {
	text-align: center;
	font-family: 'Neo Sans W01', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 1.5rem;
	line-height: 1.333;
	color: #075f28;
}

@keyframes rotator {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(270deg);
	}
}

.path {
	stroke-dasharray: $offset;
	stroke-dashoffset: 0;
	transform-origin: center;
	animation: dash $duration ease-in-out infinite,
		colors ($duration * 4) ease-in-out infinite;
}

@keyframes colors {
	0% {
		stroke: #0dad4a;
	}
	20% {
		stroke: #b5d334;
	}
	40% {
		stroke: #fdb913;
	}
	60% {
		stroke: #e36f1e;
	}
	80% {
		stroke: #e3361e;
	}
	100% {
		stroke: #0dad4a;
	}
}

@keyframes dash {
	0% {
		transform: rotate(0deg);
		stroke-dashoffset: $offset;
	}
	50% {
		stroke-dashoffset: calc($offset/4);
		transform: rotate(135deg);
	}
	100% {
		stroke-dashoffset: $offset;
		transform: rotate(450deg);
	}
}
</style>
